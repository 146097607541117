.main-img {
  width: 100%; /* Set the image width to 100% of its container */
  max-width: 100%; /* Ensure the image does not exceed its natural size */
  height: auto; /* Maintain the image's aspect ratio */
}



.pinimgcarousel{
  width: 100%;
  height: auto;

}

.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
  color: var(--bs-nav-pills-link-active-color);
  background-color: #0e3773;
 
}

.nav-link {
  color: #000;
}

.nav-link {
  background-color:white;
}


.header-column{
  background-color: #0e3773;
}


.Title-head {
  background-color: #192a51;
  color: white;
  text-align: left;

    margin-bottom: 0;
padding-left: 30px;


}

.left-detail{
  border: none;
    color: #fff;
    background-color: #6f7990;
    border-left: 1px solid #192a51;
    border-right: 1px solid #192a51;
 
    text-align: left;
}

.right-detail{
  border: none;
  color: #fff;
  background-color: #6f7990;
  border-left: 1px solid #192a51;
  
  text-align: left;
}