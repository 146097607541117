
@import url('https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,400;1,100&display=swap');


/* Apply the custom font to an element */
body {font-family: 'Kanit', sans-serif;
}

.carousel-item img {
  object-fit: cover; /* Make sure the image covers the entire container */
}

.imgcarousel{
  height: 40.625rem;
  width: 100%;
}


.Title-Page{
    background-color: #192a51;
    color: white;
    text-align: center;
    font-family: 'Kanit', sans-serif; /* Use the defined font-family */
}

.padding-Title-Page{
    
    padding-top: 0.5cm;
    padding-bottom: 0.5cm;
}

.seachbar{
  padding-bottom: 30px;
  background-color: white;
}

.slide-bar {
/* 
  height: 31.25rem;
 */
 width: 71rem; 

}

.home-trending{

  height: 450px;
  width: 550px;
}

.trending {
  display: flex; /* Make the container a flex container */
  justify-content: center; /* Center the content horizontally */
  /*align-items: center; /* Center the content vertically */
  height: 100vh; /* Optional: Set a height for the container (100vh = 100% of viewport height) */
}

.top-left {
  position: absolute;
  top: 8px;
  left: 16px;
}

.img-container{
  /* Container holding the image and the text */

  position: relative;
  text-align: center;
  color: black;

}

.image-container {
  position: relative; /* Set the container as the positioning context */
  width: 550px; /* Set the width of the container */
  height: 200px; /* Set the height of the container */
 
}



.caption {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
 
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent background color for the caption */
  color: white;
  font-size: 18px; /* Adjust the font size as needed */
}

.col-md-6 {
  height: 28.125rem;
}

.trending {
  height: 28.125rem;
}

 .type-post-img {
  width: 100%; /* Make the image width 100% of the div */
  height: auto; /* Automatically adjust the height to maintain aspect ratio */
}

.type-post-img-second{
  width: 100%; /* Make the image width 100% of the div */
  height: auto; /* Automatically adjust the height to maintain aspect ratio */
}

.logo-container{
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  height: 15rem; /* Optional: Makes the container take the full viewport height */

}
.logo-site{
  max-width: 100%; /* Make the image width 100% of the div */
  max-height: 100%; /* Automatically adjust the height to maintain aspect ratio */
}

.main-div-img{
  background-image: url('../../img/4907157.jpg');
}

.text-overlay {
  position: absolute;
  color: #192a51;
  width: 100%;
  height: 2rem;
 /* background-color: rgba(0, 0, 0, 0.5); /* Adjust the alpha value (0.5) for opacity */
}

.second-txt{
  padding-top: 80px;
}

.logo-container{
  background-color: white;
}

.banner
{
  width: 100%;
  height: 100%;
}

/* 
@media only screen and (min-width: 960px) {
  .banner
  {
    width: 960px;
    height: 100%;
  }
}
@media only screen and (min-width: 1440px) {
  .banner
  {
    width: 1440px;
    height: 100%;
  }
}
@media only screen and (min-width: 2000px) {
  .banner
  {
    width: 2000px;
    height: 100%;
  }
}
@media only screen and (max-device-width: 480px) {
 .banner
  {
    width: 480px;
    height: 100%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {

  .banner {
    width: 1024px;
    height: 100%;
  }
}


@media only screen and (device-width: 768px) {
 .banner
  {
    width: 768px;
    height: 100%;
  }
} */

/* External stylesheet or within your HTML file */
.card-img {
  width: 100%;
  height: auto;
  border: 1px solid #192a51; 

}

.type-post-img-second {
  width: 100%;
  height: auto;
  border: 1px solid #192a51; 

}


.custom-button {
  background-color: #192a51;  /* Replace 'yourColor' with the desired color code or name */
  color: #fff; /* Text color, adjust as needed */
  /* Add any additional styles you want */
}

.custom-button:hover {
  background-color: #192a51;
}

.seachbar {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Adjust the values as needed */
  /* Add any additional styles for your seachbar */
}

.seach-link{
  color: white;
}

.card-link{
  color: #192a51;
} 

