.footer-home{
    background-color: #192a51;
    color: white;
}

.bt-footer{
    background-color: #54456b;
}

.button-lower{

    background-color: rgba(0, 0, 0, 0.2);

} 

