.main-div-img{
    background-image: url('../../img/4907157.jpg');
  }

  .modal-header{
    background-color: #192a51;
    color: white;
  }

  .input-required{
    background-color: #E4EDFE ;
  }


  .bedlogo{
    width: 60px;
  }

  .promotion{
    background-color: #192a51;
    color: white;
  }


.titile{
  background: rgb(2,0,36);
  background: linear-gradient(0deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 6%);
  color: white;
}

.titile-2{
  background: rgb(86,40,6);
  background: linear-gradient(180deg, rgba(86,40,6,1) 8%, rgba(167,129,20,1) 75%);
  color: white;
}

.titile-3{
  background: rgb(98,131,52);
  background: linear-gradient(2deg, rgba(98,131,52,1) 0%, rgba(72,101,27,1) 95%);
  color: white;
}




.custom-title {
  font-size: 30px;
    border-bottom: 2px solid #b0264a;
}

.custom-title-2 {
  font-size: 30px;
    border-bottom: 2px solid #1b050b;
}

.custom-title-3 {
  font-size: 30px;
    border-bottom: 2px solid white;
}

/* Responsive font size for iPad */
@media only screen and (max-width: 1024px) {
  .custom-title {
      font-size: 50px;
  }
}

/* Responsive font size for iPhone */
@media only screen and (max-width: 767px) {
  .custom-title {
      font-size: 30px;
  }
}

.breadcrumb-item{
  color: #192a51;
}
.addbutton{
  background-color: #cea32f;
  
}

.addbutton:hover{
  background-color: #562806;
  
}
/* .line-under{
  text-decoration: underline;
  -webkit-text-decoration-color:#b0264a;
  text-decoration-color: #b0264a;
  
} */