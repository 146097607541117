.Map-location{
    width: 100%;
}

.contractheader{
    color: #192a51;
}

.contract-detail{
    color: #777889;
}