.main-div-img{
    background-image: url('../../img/4907157.jpg');
  }

  .datecard{
    color: #0e3773;
  }

  .contentcard{
    color: #888C95;
    margin-bottom: 0.5rem;
  }