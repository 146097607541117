/* .top{
    width: 100%;
    height: 50px;
    background-color: aqua;
} */
@import url('https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,400;1,100&display=swap');
.logo-web-site {
    width: auto;
    height: 60px;
  }





/* Apply the custom font to an element */
body {font-family: 'Kanit', sans-serif;
}
.navbar{
    font-family: 'Kanit', sans-serif;
}

#menu-appbar{
    font-family: 'Kanit', sans-serif;
}

.MenuItem-text{
    font-family: 'Kanit', sans-serif;
}

* {
    box-sizing: border-box;
  }
  
  body {
    margin: 0;
    padding: 0;
  }
  
  .navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #333;
    color: #fff;
  }
  
  .brand-title {
    font-size: 1.5rem;
    margin: 0.8rem;
  }
  
  .navbar-links ul {
    margin: 0;
    padding: 0;
    display: flex;
  }
  
  .navbar-links li {
    list-style: none;
  }
  
  .navbar-links li:hover {
    background-color: #555;
  }
  
  .navbar-links li a {
    text-decoration: none;
    color: white;
    padding: 1rem;
    display: block;
  }
  
  .toggle-button {
    position: absolute;
    top: 1rem;
    right: 1rem;
    display: none;
    flex-direction: column;
    justify-content: space-between;
    width: 28px;
    height: 21px;
  }
  
  .toggle-button .bar {
    height: 3px;
    width: 100%;
    background-color: white;
    border-radius: 10px;
  }
  
  @media (max-width: 400px) {
    .toggle-button {
      display: flex;
    }
  
    .navbar {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .navbar-links {
      display: none;
      width: 100%;
    }
  
    .navbar-links ul {
      width: 100%;
      flex-direction: column;
    }
  
    .navbar-links li {
      text-align: center;
    }
  
    .navbar-links li a {
      padding: 0.5rem 1rem;
    }
  
    .navbar-links.active {
      display: flex;
    }
  }
  